html {
  font-size: 62.5% !important;
}

//大于500px的屏幕（一般都不是手机屏幕），内容区域宽度还是500px，让它居中即可
@media only screen and (min-width: 500px) {
  .globalcontainer {
    width: 500px !important;
    margin: 0 auto !important;
  }
}

//解决tabbar不固定在底部的问题
.am-tab-bar {
  position: fixed;
  overflow: auto;
  bottom: 0;
  width: 100%;
  background-color: #fff;
}

//手风琴样式重置
.am-accordion .am-accordion-item .am-accordion-header {
  font-size: 1.5rem !important;
}

.category .am-list-item .am-list-line .am-list-content {
  font-size: 1.2rem !important;
}

.address {
  .am-list-item .am-list-line .am-list-extra {
    flex-basis: 60%;
  }

  .am-list-item.am-list-item-middle .am-list-line {
    border-bottom: 1px solid #ddd;
  }

  .am-list-item .am-input-control input {
    color: #888;
  }
}
@brand-primary: #42bb84;